import React, { useState, useEffect, useRef } from "react"
import { Progress } from '@geist-ui/react'

const WorkoutSeconds= (props) => {

  const [num, setNum] = useState(props.timer)
  const [paused, setPaused] = useState(true)
  const [roundtotal, setRoundTotal] = useState(0)
  
  let intervalRef = useRef(); 
  let prevTimer = useRef();

  const totaltime = props.strength ? 2400 : (props.repeat ? props.timer : 150);

  const decreaseNum = () => {
    setNum((prev) => prev > 0 ? prev - 1 : 0);
    if(num >= 1) {
      setRoundTotal((prev) => prev < totaltime ? prev + 1 : totaltime);
    }
    if(num < 1) {
      props.onChange(props.timer);
      if(props.timer === 60) {
        setRoundTotal(0);
      }
    }
  }

  useEffect(() => {
     if(num >= 0 && !paused) {
       intervalRef.current = setInterval(decreaseNum, 1000);
     }
     else {
       clearInterval(intervalRef.current);
     }
      return () => {
        clearInterval(intervalRef.current);
      }
    }, [num, paused]);

  useEffect(() => {
     setNum(props.timer);
     if((props.timer === 30 || props.timer === 180) && prevTimer.current === 60) {
       setRoundTotal(0);
     }
     prevTimer.current = props.timer;
    }, [props.timer]);

  const checkVisible = () => {
    if(!paused) {
      if(document.visibilityState === 'visible') {
        console.log('done');
      }
    }
  }


  return (<>
    {props.intervals && !props.repeat && <p>{Math.floor((totaltime - roundtotal) /60)}:{(totaltime - roundtotal) % 60 < 10 ? '0' + (totaltime - roundtotal) % 60 : (totaltime - roundtotal) % 60}</p>}
    <Progress value={roundtotal} max={totaltime} />
    {((props.intervals && !props.repeat) || props.timer === 60) && <h1 className={props.timer === 30 ? "seconds" : "rest seconds"} onClick={() => setPaused(!paused)}>
      {num.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}
    </h1>}
    {(!props.intervals || props.repeat) && props.timer !== 60 && <h1 className="seconds cooldown" onClick={() => setPaused(!paused)}>{Math.floor((totaltime - roundtotal) /60)}:{(totaltime - roundtotal) % 60 < 10 ? '0' + (totaltime - roundtotal) % 60 : (totaltime - roundtotal) % 60}</h1>}
    </>
  )
}

export default WorkoutSeconds
