import React, { useState, useEffect, useRef } from "react"
import { Progress, Spacer } from '@geist-ui/react'

const WorkoutWarmup = (props) => {

  const [num, setNum] = useState(props.shortwarmup ? 300 : 600)
  const [paused, setPaused] = useState(true)
  const [roundtotal, setRoundTotal] = useState(0)
  
  let intervalRef = useRef(); 


  const totalnum = props.shortwarmup ? 300 : 600

  const decreaseNum = () => {
    setNum((prev) => prev > 0 ? prev - 1 : 0);
    if(num >= 1) {
      setRoundTotal((prev) => prev < totalnum ? prev + 1 : totalnum);
    }
  }

  useEffect(() => {
     if(num >= 0 && !paused) {
       intervalRef.current = setInterval(decreaseNum, 1000);
     }
     else {
       clearInterval(intervalRef.current);
     }
      return () => {
        clearInterval(intervalRef.current);
      }
    }, [num, paused]);


  return (<>
    <h5>Warm Up</h5>
    <Spacer y={1} />
    <Progress value={roundtotal} max={totalnum} />
    <h1 className="seconds cooldown" onClick={() => setPaused(!paused)}>{Math.floor((totalnum - roundtotal) /60)}:{(totalnum - roundtotal) % 60 < 10 ? '0' + (totalnum - roundtotal) % 60 : (totalnum - roundtotal) % 60}</h1>
    {props.workout.warmupheadings.map((heading, index) => (
      <h3 className={index < 2 ? "warmup" : "warmup right"} style={{marginTop: (index === 1 || index === 3) ? '30vh' : '0'}}><div>
        <span>{heading}</span><br />{props.workout.warmuprounds[index].split(", ").map((movement) => (<>
        {movement}<br /></>))}
      </div></h3>))}
    </>
  )
}

export default WorkoutWarmup