import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import WorkoutTimer from './WorkoutTimer'
import SpotifyLogin from './SpotifyLogin'
import { Loading, Card, Input, Select, Spacer, Checkbox, Toggle } from '@geist-ui/react'
import { Search, Info, X, PlayCircle, Headphones, ChevronRight } from '@geist-ui/react-icons'

import Spotify from '../../static/assets/Spotify.png'


const Workout = (props) => {

const [showcircuit, setShowCircuit] = useState()
const [type, setType] = useState("Fundamentals")
const [playing, setPlaying] = useState()
const [round, setRound] = useState()
const [music, setMusic] = useState()
const [showintervals, setShowIntervals] = useState(true)
const [shortwarmup, setShortWarmup] = useState()
const [showroad, setShowRoad] = useState()

useEffect(() => {
  if(playing) {
    setMusic(true);
  }
  else {
    setMusic(false);
  }
}, [playing])

useEffect(() => {
  if(props.workout.rounds.length === 1) {
    setShowIntervals(false);
    setShowCircuit(true);
    setShortWarmup(true);
  }
}, [props.workout])

const workout = props.workout
const quotient = workout.rounds.length / workout.headings.length
const roadmode = [0, 1, 5, 6, 2, 3, 7, 8, 4, 9]
const circuitroadmode = [
  [[0, 5], [1, 6]],
  [[0, 5], [1, 6]],
  [[2, 7], [3, 8]],
  [[2, 7], [3, 8]],
  [[4, 9]],
  [[4, 9]]
]
const circuitroadseparated = [
  [0, 5], [1, 6],
  [0, 5], [1, 6],
  [2, 7], [3, 8],
  [2, 7], [3, 8],
  [4, 9],
  [4, 9]
]

  return (
    <div className="workout-page">
      {!playing && <div style={{padding: '0 5%'}}>
        <h6 className="centered">{workout.title}: {workout.type}</h6>
        {workout.coach_name && <p>{workout.coach_name}</p>}
        {workout.video && 
          <div className="video">
            <iframe width="232" height="413" 
              allowFullScreen src={`https://ljsp.lwcdn.com/api/video/embed.jsp?id=${workout.video}&pi=b4456011-9ee5-4531-b535-132856534493`} title="0" byline="0" portrait="0" frameBorder="0" allow="autoplay"></iframe>
          </div>
        }
        <div className="info">
          <div>Style<br /><span className="value">{workout.style}</span></div>
        </div>
        <div className="workout-toggle">
          <div className="toggle-container">
            <Toggle type="secondary" onChange={() => setShowCircuit(!showcircuit)} checked={showcircuit} />
            <span className="toggle-name">Circuit Format</span>
          </div>
        </div>
        <div className="workout-toggle">
          <div className="toggle-container">
            <Toggle type="secondary" onChange={() => setShowIntervals(!showintervals)} checked={!showintervals} />
            <span className="toggle-name">No Intervals</span>
          </div>
        </div>
        <div className="workout-toggle">
          <div className="toggle-container">
            <Toggle type="secondary" onChange={() => setShortWarmup(!shortwarmup)} checked={shortwarmup} />
            <span className="toggle-name">Short Warm Up</span>
          </div>
        </div>
        {workout.headings.length === 2 && workout.rounds.length === 10 && <div className="workout-toggle">
          <div className="toggle-container">
            <Toggle type="secondary" onChange={() => setShowRoad(!showroad)} checked={showroad} />
            <span className="toggle-name">Road Mode</span>
          </div>
        </div>}
        <h6>Warm Up</h6>
        <Card onClick={() => (setRound('Warm Up'), setPlaying(true))}>
            <div className="round">{shortwarmup ? 5 : 10} MIN</div>
            <div className="movements">
              {workout.warmupheadings.map((warmupheading, index) => (
                <div className={workout.warmupheadings.length < 4 ? "movement circuit third" : 
                workout.warmupheadings.length < 5 ? "movement circuit fourth" : "movement circuit"}><span>{warmupheading}</span>{workout.warmuprounds[index]}</div>))}
            </div>
            <PlayCircle />
        </Card>

        {!showcircuit && !showroad && workout.headings.map((heading, index) => ( <>
          <h6>{heading}</h6>
          {workout.rounds.slice(index * quotient, (index * quotient) + quotient).map((round) => (
          <Card onClick={() => (setRound(workout.rounds.indexOf(round)), setPlaying(true))}>
            <div className="round">Round {workout.rounds.indexOf(round) + 1}</div>
            <div className="movements">
              <div className="movement"><span></span>{round}</div>
            </div>
            <PlayCircle />
          </Card>))}
        </>))}

        {showcircuit && !showroad && workout.headings.map((heading, index) => ( <>
          <h6>Circuit {index + 1}</h6>
          {workout.rounds.slice(index * quotient, (index * quotient) + quotient).map((round, i) => (
            <Card onClick={() => (setRound(workout.rounds.indexOf(round)), setPlaying(true))}>
              <div className="round">Round {workout.rounds.indexOf(round) + 1}</div>
              <div className="movements">
                {workout.headings.map((heading, index) => (
                  <div className={workout.headings.length < 4 ? "movement circuit third" : 
                    workout.headings.length < 5 ? "movement circuit fourth" : "movement circuit"}><span>{heading}</span>{workout.rounds[index * quotient + i]}</div>
                ))}</div>
              <PlayCircle />
            </Card>
          ))}
        </>))}

         {!showcircuit && showroad && roadmode.map((num, index) => ( <>
          {(index === 0 || index === 4 || index === 8) && <h6>{workout.headings[0]}</h6>}
          {(index === 2 || index === 6 || index === 9) && <h6>{workout.headings[1]}</h6>}
          <Card onClick={() => (setRound(roadmode.indexOf(num)), setPlaying(true))}>
            <div className="round">Round {index + 1}</div>
            <div className="movements">
              <div className="movement"><span></span>{workout.rounds[num]}</div>
            </div>
            <PlayCircle />
          </Card>
        </>))}

        {showcircuit && showroad && circuitroadmode.map((arrays, index) => ( <>
          <h6>Circuit {index + 1}</h6>
         {arrays.map((num, indx) => ( 
           <Card onClick={() => (setRound(((2 * index) + (index === circuitroadmode.length  - 1 ? -1 : 0)) + indx), setPlaying(true))}>
              <div className="round">Round {((2 * index) + (index === circuitroadmode.length  - 1 ? 0 : 1)) + indx}</div>
              <div className="movements">
                    {num.map((number, ind) => ( 
                      <div className="movement circuit third">
                       <span>{workout.headings[ind]}</span>{workout.rounds[number]}
                     </div>))}
              </div>
              <PlayCircle />
            </Card>
          ))}
        </>))}

        {workout.burner && <><h6>Championship Round</h6>
        <Card onClick={() => (setRound('Burner'), setPlaying(true))}>
            <div className="round">3 MIN</div>
            <div className="movement">{workout.burner}</div>
            <PlayCircle />
        </Card></>}

        <h6>Cool Down</h6>
        <Card onClick={() => (setRound('Complete'), setPlaying(true))}>
            <div className="round">5 MIN</div>
            <div className="movement">Cool Down</div>
            <PlayCircle />
        </Card>

        <Spacer y={4} />
      </div>}
      {playing && <>
        <div className="close" onClick={() => (setPlaying(), setMusic())}><X size={30} color={"#333333"} /></div>
        <WorkoutTimer round={round} quotient={quotient} workout={workout} showcircuit={showcircuit} 
        showintervals={showintervals} shortwarmup={shortwarmup} strength={props.workout.rounds.length === 1}
        showroad={showroad} roadmode={roadmode} circuitroadmode={circuitroadseparated} />
      </>}
    </div>
  )
}

export default Workout
