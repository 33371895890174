import React, { useState, useEffect } from "react";
import SpotifyPlayer, { STATUS, CallbackState } from 'react-spotify-web-playback'
import { Modal } from '@geist-ui/react'
import { RefreshCcw, List } from '@geist-ui/react-icons'

import Spotify from '../../static/assets/Spotify.png'

function SpotifyLogin(props) {
  const [params, setParams] = useState({});
  const [token, setToken] = useState();
  const [id, setId] = useState();
  const [playlists, setPlaylists] = useState([]);
  const [selectingplaylist, setSelectingPlaylist] = useState();
  const [selectedplaylist, setSelectedPlaylist] = useState();

  const getHashParams = (location = window.location) => {
  const q = location.hash.substring(2);

  // if the substring is non-empty, split it up based on hashes
  // return the parameters as an object
  if (q) {
    const qArray = q.split("/");
    const hashParams = {
      path: qArray[0],
      access_token: qArray[1],
      refresh_token: qArray[2],
      expires_in: qArray[3],
    };
    return hashParams;
  }
  return null;
};

  useEffect(() => {
    const authData = getHashParams(window.location);
    if (authData) {
      if (authData.path === "error") {
        alert("There was an error during the authentication.");
      } else {
        setParams(authData);
      }
    }
  }, []);


  useEffect(() => {
    if (params.access_token) {
      setToken(params.access_token);
    }
  }, [params]);

  useEffect(() => {
    if (token) {
     console.log('me');
     fetch(`/.netlify/functions/spotify-me/`, {
        body: JSON.stringify({
          token: token
        }),
        method: 'POST'}).then((response) => {
          return response.json()}).then((response) => {
            fetch(`/.netlify/functions/spotify-playlists/`, {
              body: JSON.stringify({
                id: response.user.id,
                token: token
              }),
              method: 'POST'}).then((response) => {
                return response.json()}).then((response) => {
                  if(response.playlists) {
                    setPlaylists(response.playlists.items || []);
                  }
              })
             .catch(error => console.log(error))
          })
       .catch(error => console.log(error))
    }
  }, [token]);


  // get new parameters, using the current refresh token
  const refreshToken = () => {
    return fetch(
      `/.netlify/functions/spotify?refresh_token=${params.refresh_token}`
    )
      .then((response) => {
        return response.json();
      })
      .then((jsonResponse) => {
        setParams(jsonResponse);
        if(!jsonResponse.err){
          alert('Spotify refreshed!');
        }
        else {
          alert('Error, please login again');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // clear out the authentication parameters
  const clearTokens = () => {
    setParams(null);
  };

  return (<>
   {playlists.length > 0 && 
     <Modal open={selectingplaylist} onClose={() => setSelectingPlaylist(false)}>
       {selectedplaylist && <p onClick={() => (setSelectedPlaylist(), setSelectingPlaylist(false))}>Back to original playlist</p>}
       {playlists.map((playlist) => (
         <div className="playlist-item" onClick={() => (setSelectedPlaylist(playlist.id), setSelectingPlaylist(false))}>
           <img src={playlist.images[0].url} width="60px" alt={playlist.name} /> 
           <span>{playlist.name} : {playlist.tracks.total} tracks</span>
         </div>
      ))}
    </Modal>}
    <div className={props.open ? "music-player" : "music-player hide"}>
      {token && <SpotifyPlayer
        token={token}
        uris={[`spotify:playlist:${selectedplaylist ? selectedplaylist : props.playlist}`]}
        styles={{
        activeColor: '#fff',
        bgColor: '#000',
        color: '#fff',
        loaderColor: '#fff',
        sliderColor: '#e5c8a3',
        trackArtistColor: '#000',
        trackNameColor: '#fff',
      }}
       magnifySliderOnHover="true"
      />}
      <div className="spotify-buttons">
          {playlists.length > 0 && <span><List size={30} color={"#333333"} onClick={() => setSelectingPlaylist(true)}/></span>}
          <a href="/.netlify/functions/spotify-login">
            <img src={Spotify} width="30px" style={{opacity:'.25'}} />
          </a>
          <RefreshCcw size={26} color={"#333333"}
            onClick={refreshToken}
            disabled={!params}
          />
      </div>
    </div>
    </>
  );
}

export default SpotifyLogin;