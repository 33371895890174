import React, { useState, useEffect, useRef } from "react"
import { graphql, navigate } from 'gatsby'
import WorkoutSeconds from './WorkoutSeconds'
import WorkoutWarmup from './WorkoutWarmup'
import WorkoutCooldown from './WorkoutCooldown'
import { Loading, Card, Input, Select, Spacer, Progress, Checkbox, Toggle } from '@geist-ui/react'
import { CheckCircle, Pause, Play, ChevronRight } from '@geist-ui/react-icons'
import Logo from '../../static/assets/CbCo.svg'


const RepeatTimer = () => {

  const now = new Date()

  const [intervaltotal, setIntervalTotal] = useState(0)
  const [selectedvideo, setSelectedVideo] = useState('work')

  const roundEl = document.getElementsByClassName("start-round")[0]
  const intervalEl = document.getElementsByClassName("end-interval")[0]


  useEffect(() => {
   document.getElementById("workout-container").classList.add("timing");
   return () => {
       document.getElementById("workout-container").classList.remove("timing");
    }
  }, []);
 

  useEffect(() => {
     document.getElementsByTagName("header")[0].style.display = "none";
     document.getElementById("back").style.marginTop = "40px";
      return () => {
       document.getElementsByTagName("header")[0].style.display = "flex";
      }
  }, []);


  const changeVideo = (e) => {
    if(e === 180) {
      setSelectedVideo('rest');
    }
    else {
        roundEl.play();
        setSelectedVideo('work');
    }
  }


  return (
    <div className="workout-page">
      <div className="workout-timer">
      <img src={Logo} className="dark-logo" width="10%" height="auto" alt="Craft Boxing Co Logo" />
          
          <h5>ROUND TIMER</h5>
          <Spacer y={2} />

          <WorkoutSeconds timer={selectedvideo === 'work' ? 180 : 60} 
           onChange={changeVideo} intervals={true} repeat={true} />

           <h6>{selectedvideo === 'work' ? <span>WORK</span> : <span className="rest">REST</span>}</h6>
          
           
           {selectedvideo !== 'work' && <>
             <div className="start" onClick={() => (roundEl.play(), setSelectedVideo('work'), setIntervalTotal(0))}>
               Start Round <ChevronRight color="#e5cab3" />
              </div>
            
            </>}

           <audio className="start-round">
              <source src="https://cdn.shopify.com/s/files/1/0512/4102/5695/files/boxing-bell.mp3?v=1636743841"></source>
           </audio>
           <audio className="end-interval">
              <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
           </audio>

      </div>
     </div>
  )
}

export default RepeatTimer
